const headers = [
    {
      id: 1,
      header: "Udemy Projects",
    },
    {
      id: 2,
      header: "Personal Projects",
    },
    {
      id: 3,
      header: "Work Projects",
    },
]

export default headers;